<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
					<el-form-item label="关键字:">
						<el-input v-model='filters.keyWord' placeholder="请输入名称" clearable @clear='handleClear'></el-input>
					</el-form-item>
					<el-form-item label="行业名称:">
						<el-select v-model="filters.tradeId" style="width: 120px" clearable placeholder="请选择">
							<el-option
									v-for="item in tradeList"
									:key="item.Id"
									:label="item.Remark"
									:value="item.Id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="岗位名称:">
						<el-select v-model="filters.stationId" style="width: 120px" clearable placeholder="请选择">
							<el-option
									v-for="item in stationList"
									:key="item.Id"
									:label="item.Remark"
									:value="item.Id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否邮寄教材:">
						<el-select v-model="filters.isPostMaterial" style="width: 120px" clearable placeholder="请选择">
							<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.name"
									:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
<!--					<el-form-item label="邮寄状态:">-->
<!--						<el-select v-model="filters.isPayed" style="width: 120px" clearable placeholder="请选择">-->
<!--							<el-option-->
<!--									v-for="item in options3"-->
<!--									:key="item.value"-->
<!--									:label="item.name"-->
<!--									:value="item.value"-->
<!--							>-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<el-table
				:data="tableData"
				border
				v-loading="listLoading"
				@current-change="selectCurrentRow"
				highlight-current-row
				style="width: 100%;margin-bottom: 20px"
		>
			<el-table-column prop="Code" type="index"  label="序号" align="center" :index='indexMethod' width="80"></el-table-column>
			<el-table-column prop="Name" label="姓名" align="center" width="100"></el-table-column>
			<el-table-column prop="PhoneNumber" label="手机号" align="center" width="120"></el-table-column>
			<el-table-column prop="CardNo" label="身份证号" align="center" width=""></el-table-column>
			<el-table-column prop="StationName" label="岗位名称" align="center" width=""></el-table-column>
			<el-table-column prop="TradeName" label="行业名称" align="center" width=""></el-table-column>
			<el-table-column prop="UnitName" label="单位名称" align="center" width="240"></el-table-column>
			<el-table-column prop="isNeedPost" label="是否邮寄教材" align="center" width="120">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.IsNeedPost===1" type="success">是</el-tag>
					<el-tag v-else type="danger">否</el-tag>
<!--					<el-tag v-else type="danger">未邮寄</el-tag>-->
				</template>
			</el-table-column>
			<el-table-column prop="DetailAddress" label="教材邮寄地址" show-overflow-tooltip align="center" width=""></el-table-column>
			<el-table-column prop="PostStatus" label="教材邮寄状态" align="center" width="">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.PostStatus === -5" type="danger">数据异常</el-tag>
					<el-tag v-if="scope.row.PostStatus === -4" type="success">待完善地址</el-tag>
					<el-tag v-if="scope.row.PostStatus === -3" type="success">无需邮寄</el-tag>
					<el-tag v-if="scope.row.PostStatus === -2" type="success">待发起寄出</el-tag>
					<el-tag v-if="scope.row.PostStatus === 1" type="success">待邮寄</el-tag>
					<el-tag v-if="scope.row.PostStatus === 2" type="success">待发货</el-tag>
					<el-tag v-if="scope.row.PostStatus === 3" type="success">待签收</el-tag>
					<el-tag v-if="scope.row.PostStatus === 4" type="success">已签收</el-tag>
				</template>
			</el-table-column>
		
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar" style="margin:20px 0">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<!--	新增字典    -->
		<el-dialog
				title="完善地址"
				:visible.sync="viewDialog"
				v-model="viewDialog"
				:close-on-click-modal="false"
				width="55%"
		>
			<el-form label-width="120px">
				<el-form-item label="姓名:">
					<el-input style="width: 90%" v-model="detailNeedData.name" disabled></el-input>
				</el-form-item>
				<el-form-item label="手机号:">
					<el-input style="width: 90%" v-model="detailNeedData.phoneNumber" disabled></el-input>
				</el-form-item>
				
				<div style="display: flex;">
					<el-form-item label="教材邮寄地址:">
					</el-form-item>
					<el-form inline>
						<el-form-item label="省:" required>
							<el-select
									:disabled="isChange"
									@change="selectRowChange1"
									v-model="addressData.provinceCode"
									placeholder="请选择"
									ref ='cityRef'
									v-loading='cityLoading'
									style="width: 80%"
									clearable
							>
								<template v-for="item in shengArr">
									<el-option
											:key="item.Id"
											:label="item.Name"
											:value="item.Id"
									>
									</el-option>
								</template>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" required>
							<el-select
									:disabled="isChange"
									@change="selectRowChange2"
									v-model="addressData.cityCode"
									placeholder="请选择"
									ref ='cityRef'
									v-loading='cityLoading'
									style="width: 80%"
									clearable
							>
								<template v-for="item in shiArr">
									<el-option
											:key="item.Id"
											:label="item.Name"
											:value="item.Id"
									>
									</el-option>
								</template>
							</el-select>
						</el-form-item>
						<el-form-item label="区/县:" required>
							<el-select
									:disabled="isChange"
									@change="selectRowChange3"
									v-model="addressData.districtCode"
									placeholder="请选择"
									ref ='regionRef'
									v-loading='areaLoading'
									style="width: 80%"
									clearable
							>
								<template v-for="item in xianArr">
									<el-option
											:key="item.Id"
											:label="item.Name"
											:value="item.Id"
									>
									</el-option>
								</template>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
<!--				<el-form-item label="乡镇/街道:">-->
<!--					<el-select-->
<!--							:disabled="isChange"-->
<!--							v-model="addSocialFrom.selecJie"-->
<!--							placeholder="请选择"-->
<!--							ref ='regionRef'-->
<!--							v-loading='jieLoading'-->
<!--							style="width: 300px;"-->
<!--							clearable-->
<!--					>-->
<!--						<template v-for="item in jieArr">-->
<!--							<el-option-->
<!--									:key="item.ID"-->
<!--									:label="item.Name"-->
<!--									:value="item.ID"-->
<!--							>-->
<!--							</el-option>-->
<!--						</template>-->
<!--					</el-select>-->
<!--				</el-form-item>-->
				<el-form-item label=""  >
					<div style="display: flex">
						<el-input style="width: 60%" v-model='detailNeedData.address' placeholder="请输入教材邮寄地址" clearable></el-input>
						<div style="margin-left: 20px;">
							<input
									type="checkbox"
									id="subscribeNews"
									name="subscribe"
									@change="changeValue"
									:value="detailNeedData.isNeedPost" />
							<label for="subscribeNews">已邮寄教材，无需重复邮寄</label>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="viewDialog = false">取 消</el-button>
				<el-button type="primary" @click="confirmAdd">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import {
	getAreaDictionaryList,
	getDeletedStaffsAuthoritySetting, getlearningbookmailstatuslist,
	getLearnRecordListPage, getStationTradeList, getStudyRecordDetail, managerFillAddress, syncPostInfo
} from '../../api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
	components: {Toolbar},
	data() {
		return {
			activeName: '0',
			pages : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			pagesStudy : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			viewDialog : false,
			detailNeedData:{
				name:'',//
				phoneNumber:'',//
				orderCode:'',//订单号
				studentLearnRecordId:'',//报名学习Id
				learningFee:'',//培训费用
				provinceCode:'',//省Code
				cityCode:'',//市Code
				districtCode:'',//区县Code
				address:undefined,//详细地址
				orderInfoId:undefined,//订单Id
				isNeedPost:true,//是否需要邮寄教材
				stationId:undefined,//岗位Id
				tradeId:undefined,//行业Id
				Ip:undefined,//
			},
			addressData:{
				provinceCode:'',//省Code
				cityCode:'',//市Code
				districtCode:'',//区县Code
			},
			dictionaryList:[],
			filters: {
				stationId:undefined,//岗位ID
				tradeId:undefined,//行业信息ID
				keyWord:undefined,//搜索关键字
				socialUnitName:undefined,//单位名称
				isPostMaterial:undefined,//是否邮寄教材 0:未 1:已
			},
			stationList:[],
			tradeList:[],
			options3: [
				{value:3,name:'已签收'},
				{value:2,name:'代签收'},
				{value:1,name:'代发货'},
				{value:-1,name:'待邮寄'},
				{value:-2,name:'待发起寄出'},
				{value:-3,name:'无需邮寄'},
				{value:-4,name:'待完善地址'},
				{value:-5,name:'数据异常'},
			],
			options: [
				{value:1,name:'是'},
				{value:2,name:'否'},
			],
			buttonList: [],
			tableData: [],
			tableDataStudy: [],
			tableDataQuestion: [],
			tableDataSimulation: [],
			listLoading: false,
			listStudyLoading: false,
			listQuestionLoading: false,
			listSimulationLoading: false,
			needData:undefined,
			listLoading1:false,
			listLoading2:false,
			listLoading3:false,
			parentSheng:undefined,
			parentShi:undefined,
			shengArr:[],
			shiArr:[],
			xianArr:[],
			jieArr:[],
			provinceLoading:false,
			cityLoading:false,
			areaLoading:false,
			jieLoading:false,
			isChange:false,
			
		}
	},
	methods: {
		async mailSimultaneously(){
			if (this.needData){
				let params = {
					StudentLearnRecordId:this.needData.Id
				}
				const res = await syncPostInfo(params)
				if (res.data.Success){
					this.$message({
						message: res.data.Message,
						type: 'success'
					})
					this.getListData()
				}else {
					this.$message({
						message: res.data.Message,
						type: 'warning'
					})
				}
			}else {
				this.$message({
					message: '请选择需要同步的数据！',
					type: 'warning'
				})
			}
		},
		changeValue(val){
			this.detailNeedData.isNeedPost = !this.detailNeedData.isNeedPost
			console.log(val,'1111eeeee')
		},
		async confirmAdd(){
			if (!this.addressData.provinceCode){
				this.$message({
					message: '请选择省',
					type: 'warning'
				})
				return
			}
			if (!this.addressData.cityCode){
				this.$message({
					message: '请选择市',
					type: 'warning'
				})
				return
			}
			if (!this.addressData.districtCode){
				this.$message({
					message: '请选择区/县',
					type: 'warning'
				})
				return
			}
			const province= this.shengArr.find(item=>item.Id===this.addressData.provinceCode)
			const shi= this.shiArr.find(item=>item.Id===this.addressData.cityCode)
			const xian= this.xianArr.find(item=>item.Id===this.addressData.districtCode)
			// console.log(province,'kdkkdkdk')
			console.log(xian,'kdkkdkdk')
			let params = {
				// OrderCode:this.needData.OrderCode,
				StudentLearnRecordId:this.needData.Id,
				// LearningFee:this.needData.LearningFee,
				OrderInfoId:this.needData.OrderInfoId,
				StationId:this.needData.StationId,
				TradeId:this.needData.TradeId,
				IsNeedPost_Boolean:this.detailNeedData.isNeedPost,
				ProvinceCode:province?province.Code:undefined,
				CityCode:shi?shi.Code:undefined,
				DistrictCode:xian?xian.Code:undefined,
				Address:this.detailNeedData.address,
			}
			const res = await managerFillAddress(params)
			if (res.data.Success){
				this.$message({
					message: res.data.Message,
					type: 'success'
				})
				this.getListData()
				this.viewDialog = false
			} else {
				this.$message({
					message: res.data.Message,
					type: 'warning'
				})
			}
		},
		// 省选中
		selectRowChange1(row) {
			if(!row){
				this.shiArr = []
				this.xianArr = []
				this.detailNeedData.cityCode = ''
				this.detailNeedData.districtCode = ''
			}else{
				this.parentSheng = row
				// this.pages1Change2()
				var params = {
					parentId: row,
					// level:1
				}
				this.getPlace(params,1)
			}
		},
		// 市选中
		selectRowChange2(row) {
			if(!row){
				this.xianArr = []
				this.jieArr = []
				this.detailNeedData.districtCode = ''
			}else{
				this.xianArr = []
				this.jieArr = []
				this.detailNeedData.districtCode = ''
				this.parentShi = row
				var params = {
					parentId: this.parentShi,
					// level: 2
				}
				this.getPlace(params,2)
			}
		},
		// 区/县选中
		selectRowChange3(row) {
			if(!row){
				this.jieArr = []
			}else{
				this.jieArr = []
				var params = {
					parentId: row,
					// level: 3
				}
				this.getPlace(params,3)
			}
		},
		// 获取行政区域
		getPlace(params,level){
			switch(level){
				case 0:
					this.provinceLoading = true
					break;
				case 1:
					this.cityLoading = true
					break;
				case 2:
					this.areaLoading = true
					break
				case 3:
					this.jieLoading = true
					break
			}
			getAreaDictionaryList(params).then(res => {
				if(res.data.Success){
					switch(level){
						case 0:
							this.provinceLoading = false
							this.shengArr = res.data.Response.reverse();
							break;
						case 1:
							this.cityLoading = false
							this.shiArr = res.data.Response;
							break;
						case 2:
							this.areaLoading = false
							this.xianArr = res.data.Response;
							break
						case 3:
							this.jieLoading = false
							if(res.data.Response && res.data.Response.length > 0) {
								this.jieArr = res.data.Response;
							} else {
								var newObj = {
									Name: '-',
									ID: 0,
								}
								this.jieArr.unshift(newObj)
							}
							break
					}
				}else{return}
			}).catch(() => {})
		},
		// 行政区域选项分页
		pages1Change(value) {
			this.pagesOpt1.pageIndex = value
			var params = {
				level: 0,
			}
			this.getCityPage(params);
		},
		pages1Change2(value) {
			this.pagesOpt2.pageIndex = value
			var params = {
				parentId: this.parentSheng,
			}
			this.getCityPage2(params);
		},
		pages1Change3(value) {
			this.pagesOpt3.pageIndex = value
			var params = {
				parentId: this.parentShi,
			}
			this.getCityPage3(params);
		},
		getCityPage2(params) {
			console.log(params,111111)
			this.listLoading3 = true
			getAreaDictionaryList(params).then((res) => {
				var result = res.data
				if(result.Success){
					this.shiArr = result.Response.reverse()
					this.listLoading3 = false
				}
			})
		},
		getCityPage3(params) {
			this.listLoading4 = true
			getAreaDictionaryList(params).then((res) => {
				var result = res.data
				if(result.Success){
					this.xianArr = result.Response.reverse()
					this.listLoading4 = false
				}
			})
		},
		formatSeconds  (seconds)  {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			const remainingSeconds = seconds % 60;
			return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		
		
		// 获取行业名称
		async getTradeTypeData() {
			let params = {
				namespace: 'BizService.ExamService.TradeCode'
			}
			const res = await getDeletedStaffsAuthoritySetting(params)
			if (res.data.Success) {
				this.tradeList = res.data.Response
			}
		},
		//获取岗位名称
		async getStationList() {
			let params = {
				namespace:'BizService.ExamService.StationCode',
			}
			const res = await getDeletedStaffsAuthoritySetting(params)
			if (res.data.Success) {
				this.stationList = res.data.Response
			}
		},
		callFunction(item) {
			this[item.Func].apply(this, item);
		},
		// 清除input框，重新获取数据
		handleClear(){
			this.getDataList()
		},
		// 获取分页列表
		getDataList() {
			this.listLoading = true
			let params = {
				pageIndex:this.pages.pageIndex,//当前页
				pageSize:this.pages.pageSize,//每页条数
				stationId:this.filters.stationId,//岗位ID
				tradeId:this.filters.tradeId,//行业信息ID
				keyWord:this.filters.keyWord,//搜索关键字
				isPostMaterial:this.filters.isPostMaterial,//是否邮寄 0:未邮寄 1:已邮寄
			}
			getlearningbookmailstatuslist(params).then((res) => {
				let result = res.data
				if(result.Success){
					this.tableData = result.Response.Data
					this.pages.dataCount = result.Response.DataCount
					this.listLoading = false;
				}else{
					this.listLoading = false;
				}
			})
		},
		// 查询
		getListData() {
			this.pages.pageIndex = 1;
			this.pages.pageSize = 20;
			this.getDataList();
		},
		//完善地址
		handleAdd(){
			if (!this.needData){
				this.$message({
					type: 'error',
					message: '请选择需要完善地址的数据！'
				})
				return
			}
			this.detailNeedData = {
				name:'',//
				phoneNumber:'',//
				orderCode:'',//订单号
				studentLearnRecordId:'',//报名学习Id
				learningFee:'',//培训费用
				provinceCode:'',//省Code
				cityCode:'',//市Code
				districtCode:'',//区县Code
				address:undefined,//详细地址
				orderInfoId:undefined,//订单Id
				isNeedPost:true,//是否需要邮寄教材
				stationId:undefined,//岗位Id
				tradeId:undefined,//行业Id
				Ip:undefined,//
			}
			this.addressData={
				provinceCode:'',//省Code
						cityCode:'',//市Code
						districtCode:'',//区县Code
			}
			this.detailNeedData.name = this.needData.Name
			this.detailNeedData.phoneNumber = this.needData.PhoneNumber
			var params = {
				parentId: 1,
				// level: 1,
			}
			this.getPlace(params,0)
			this.viewDialog = true;
		},
		//编辑
		handleEdit(){
		
		},
		// 删除
		handleDel(){
		
		},
		// 点击table某一行
		selectCurrentRow(val) {
			this.needData = val
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getDataList();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getDataList();
		},
		// 分页
		handleCurrentChangeStudy(val) {
			this.pages.pageIndex = val;
			this.getStudyRecordDetail(this.detailNeedData);
		},
		handleSizeChangeStudy(val){
			this.pages.pageSize = val;
			this.getStudyRecordDetail(this.detailNeedData);
		},
		
		//时间格式化
		formatCreateTime: function(row, column) {
			return !row.CreateTime || row.CreateTime == ""
					? ""
					: util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			let currentPage = this.pages.pageIndex
			let pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
	},
	watch: {
		"activeName" (val) {
			this.getStudyRecordDetail(this.detailNeedData)
		}
	},
	mounted() {
		this.getStationList()
		this.getTradeTypeData()
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getListData();
	},
}
</script>

<style lang="stylus" scoped>
.el-textarea>>>.el-textarea__inner {
	min-height 100px!important;
}
</style>
<style>
.el-tooltip__popper {
	max-width: 500px;
}
</style>